//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SkillsSection',
  components: {
    ElSlider: () => import('@/components/common/Slider'),
  },
  data() {
    return {
      options: {
        loop: true,
        duration: 1000,
        spacing: 15,
        dragStart: () => {
          this.setPause(true)
        },
        dragEnd: () => {
          this.setPause(false)
        },
      },
      current: 1,
      sliderSize: 0,
      pause: false,
      interval: null,
      showSlider: false,
      skills: [
        {
          img: 'stat.png',
          title: this.$getTextSetting('learningScreenSubtitle1'),
          desc: this.$getTextSetting('learningScreenText1'),
        },
        {
          img: 'rocket.png',
          title: this.$getTextSetting('learningScreenSubtitle2'),
          desc: this.$getTextSetting('learningScreenText2'),
        },
        {
          img: 'eye.png',
          title: this.$getTextSetting('learningScreenSubtitle3'),
          desc: this.$getTextSetting('learningScreenText3'),
        },
      ],
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize, true)
    this.resize()
  },
  beforeDestroy() {
    if (this.$refs.slider) {
      this.$refs.slider.slider.destroy()
      this.resetInterval()
    }
  },
  methods: {
    // Todo найти более элегантное решение перезагрузки слайдера
    async resize() {
      if (window.innerWidth < 640) {
        if (this.showSlider === false) {
          this.showSlider = true
          await this.$nextTick()
          this.options.slideChanged = (s) => {
            this.current = s.details().relativeSlide
          }
          if (this.$refs.slider) {
            this.$refs.slider.$forceUpdate()
            this.$refs.slider.slider.refresh(this.options)
            this.sliderSize = this.$refs.slider.slider.details().size
          }
          this.setInterval()
        }
      } else {
        this.resetInterval()
        this.showSlider = false
      }
    },
    setPause(active) {
      this.pause = active
      this.setInterval()
    },
    resetInterval() {
      clearInterval(this.interval)
    },
    setInterval() {
      if (this.$refs.slider) {
        this.resetInterval()
        this.interval = setInterval(() => {
          if (!this.pause) {
            this.$refs.slider.slider.next()
          }
        }, 3000)
      }
    },
    getDirection() {
      return this.$direction()
    },
  },
}
